







import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import PageProfile from '@improve/common-components/src/components/user/PageProfile.vue';

@Component({
  name: 'EditProfile',
  components: {
    PageProfile
  }
})
export default class EditProfile extends Vue {
}
